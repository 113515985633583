import React from 'react';
import { Video } from '@gql/generated';
import styled from 'styled-components';
import VideoPlayer from './index';

const TrailerWrapper = styled.div`
  flex: 1;
  position: relative;
  height: auto;
  padding-top: calc(${() => (100 / 1.777).toFixed(2)}%);

  @media (min-width: ${({ theme }) => theme.viewports.tablet}px) {
    min-width: 350px;
  }
`;

const TrailerVideo = styled(VideoPlayer)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

type Props = {
  video: Video;
  thumbnailImage: string;
  isLimited: boolean;
  autoPlay: boolean;
  elementID: string;
  tags?: React.ReactNode;
};

const Trailer = (props: Props) => {
  return (
    <TrailerWrapper>
      <TrailerVideo {...props} isTrailer={true} />
    </TrailerWrapper>
  );
};

export default Trailer;
